.css-jpln7h-MuiTabs-scroller {
    position: relative;
    display: inline-block;
    flex: 1 1 auto;
    white-space: nowrap;
    overflow-x: auto !important;
    width: 100%;
  }
  .textstyle{
    font-family: 'Cerebri Sans', sans-serif !important;
font-size: 12px;
font-weight: 400;
line-height: 14.4px;


  }