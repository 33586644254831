.slick-slide {
    overflow: visible;
    display: flex;
    justify-content: center;
    align-items: center;
}

.slick-list,
.slick-slide {
    overflow-y: visible !important;
    padding-top: 45px;
    padding-bottom: 45px;
}

.slick-slide img {
    width: auto;
    height: auto;
    outline: none !important;
    pointer-events: none !important;
    user-select: none !important;
}

.slick-slide.slick-center img {
    transform: scale(1.35) !important;
    transform-origin: center !important;
}

.slick-dots li {
    width: 7px;
}
.slick-dots li button::before {
    font-size: 12px;/* to increase or decrese the size of circle*/
    color: #52524a;
    opacity: 1;
}
.slick-dots li.slick-active button::before {
    color:rgb(194, 179, 10);
}